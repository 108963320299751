import { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { Light, Dark } from "./styles/Themes";
import GLobalStyles from "./styles/GlobalStyles";

import Navbar from "./components/Navbar";
import Home from "./components/sections/Home";
import About from "./components/sections/About";
import Roadmap from "./components/sections/Roadmap";
import Showcase from "./components/sections/Showcase";
import Team from "./components/sections/Team";
import Faqs from "./components/sections/Faqs";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

// Custom hook for dark mode
const useDarkMode = () => {
  const [theme, setTheme] = useState(() => {
    // Get initial theme from localStorage or default to 'light'
    const savedTheme = window.localStorage.getItem("theme");
    return savedTheme || "light";
  });

  useEffect(() => {
    // Save theme preference to localStorage
    window.localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  return [theme, toggleTheme];
};

function App() {
  const [theme, toggleTheme] = useDarkMode();
  const themeMode = theme === "light" ? Light : Dark;

  return (
    <>
      <GLobalStyles />
      <ThemeProvider theme={themeMode}>
        <Navbar theme={theme} toggleTheme={toggleTheme} />
        <Home />
        <About />
        <Roadmap />
        <Showcase />
        <Team />
        <Faqs />
        <Footer />
        <ScrollToTop />
      </ThemeProvider>
    </>
  );
}

export default App;