import React from "react";
import styled from "styled-components";
import baby from "../assets/Nfts/baby.svg";
import young from "../assets/Nfts/young.svg";
import teen from "../assets/Nfts/teen.svg";
import mature from "../assets/Nfts/mature.svg";
import aging from "../assets/Nfts/aging.svg";
import aged from "../assets/Nfts/aged.svg";

const FooterBanner = () => {
  return (
    <Section>
      <ImgContainer>
        <img src={baby} />
        <img src={teen} />
        <img src={young}/>
        <img src={mature}/>
        <img src={aging}/>
        <img src={aged}/>
      </ImgContainer>
      <Title>
        JERRY <br /> the 🐢
      </Title>
      <ContractText>
        Contract Address:<br/> 0x4E9623B7e5b6438542458f5EE828d65c24d3AF8c
      </ContractText>
      <BtnContainer>
        <JoinNow as="a" href="https://app.uniswap.org/" target="_blank" rel="noopener noreferrer">
          Buy on UniSwap
        </JoinNow>      </BtnContainer>
    </Section>
  );
};

const ContractText = styled.p`
  color: ${(props) => props.theme.body};
  font-size: ${(props) => props.theme.fontlg};
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 1px;
  text-align: center;
  z-index: 10;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  
  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontmd};
  }
  
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontsm};
    padding: 0 1rem;
  }
`;

const JoinNow = styled.button`
  display: inline-block;
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};
  outline: none;
  border: none;
  font-size: ${(props) => props.theme.fontlg};
  font-weight: 600;
  padding: 1.5rem 3rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;

  @media (max-width: 48em) {
    padding: 1rem 2rem;
  }

  @media (max-width: 30em) {
    padding: 0.5rem 2rem;
    font-size: ${(props) => props.theme.fontsm};
  }

  &:hover {
    transform: scale(0.9);
  }

  &::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    border: 2px solid ${(props) => props.theme.body};
    width: 100%;
    height: 100%;
    border-radius: 50px;
    transition: all 0.2s ease;
  }

  &:hover::after {
    transform: translate(-50%, -40%) scale(1);
    padding: 0.3rem;
  }
`;

const BtnContainer = styled.div`
  width: 35%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 48em) {
    width: 100%;
    justify-content: center;
  }
`;

const Section = styled.div`
  width: 100vw;
  height: 25rem;
  position: relative;
  border-top: 2px solid ${(props) => props.theme.text};
  background-color: ${(props) => `rgba(${props.theme.textRgba}, 0.9)`};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
    
    p {
        color: #ffffff
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.1rem;
    }

  @media (max-width: 48em) {
    height: 15rem;
    flex-direction: column;
  }
`;

const ImgContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 15rem;
    height: auto;
  }

  @media (max-width: 48em) {
    img {
      width: 10rem;
      height: auto;
    }
  }
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.body};
  text-shadow: 1px 1px 2px ${(props) => props.theme.text};
  padding: 1rem 2rem;
  z-index: 10;
  width: 35%;

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxxl};
    width: 40%;
    text-align: center;
  }

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxl};
    width: 100%;
    padding: 2rem 0;
  }
`;

export default FooterBanner;
