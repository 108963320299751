import styled from "styled-components";
import ConfettiComponent from "../Confetti";

import brett from "../../assets/Nfts/brett.jpeg";
import hoppy from "../../assets/Nfts/hoppy.jpeg";
import pepe from "../../assets/Nfts/pepe.jpeg";
import peipei from "../../assets/Nfts/peipei.jpeg";
import landwolf from "../../assets/Nfts/landwolf.jpeg";
import andy from "../../assets/Nfts/andy.jpeg";
import jerry from "../../assets/Nfts/jerry.jpeg";

const Team = () => {
  return (
    <Section id="team">
      <ConfettiComponent />
      <Title>Team</Title>
      <Container>
        <MemberComponent img={jerry} name="Jerry" position="Founder" />
        <MemberComponent img={brett} name="Brett" position="Founder" />
        <MemberComponent img={pepe} name="Pepe" position="Co Founder" />
        <MemberComponent img={hoppy} name="Hoppy" position="Director" />
        <MemberComponent img={peipei} name="Peipei" position="Artist" />
        <MemberComponent img={andy} name="Andy" position="Artist" />
        <MemberComponent
          img={landwolf}
          name="Landwolf"
          position="Social"
        />
      </Container>
    </Section>
  );
};

const MemberComponent = ({ img, name = " ", position = " " }) => {
  return (
    <Item>
      <ImgContainer>
        <img src={img} alt={name} />
      </ImgContainer>
      <Name>{name}</Name>
      {/*<Position>{position}</Position>*/}
    </Item>
  );
};

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.body};
  position: relative;
    overflow: hidden;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
    padding: 3rem;

    width: fit-content;
    font-style: italic;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const ImgContainer = styled.div`
    width: 80%;
    height: 80%;
    margin: 0 auto;
    background-color: ${(props) => props.theme.carouselColor};
    border-radius: 20px;
    padding: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.3s ease;
        display: block;
        border-radius: 16px;
    }
`;

const Name = styled.h2`
    font-size: ${(props) => props.theme.fontlg};
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: ${(props) => props.theme.text};
    margin-top: 1rem;
    transition: color 0.3s ease;
    position: relative;
`;



const Item = styled.div`
    width: calc(20rem - 4vw);
    padding: 1rem 0;
    color: ${(props) => props.theme.body};
    margin: 2rem 1rem;
    position: relative;
    border: 2px solid ${(props) => props.theme.text};
    border-radius: 20px;
    backdrop-filter: blur(4px);
    z-index: 5;
    overflow: hidden;

    // Add a fixed height to prevent container size changes
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover {
        ${Name} {
            color: ${(props) => props.theme.body};
            z-index: 2;
        }

        ${ImgContainer} {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            border-radius: 18px;

            img {
                transform: scale(1.1);
            }
        }
    }

    @media (max-width: 30em) {
        width: 70vw;
    }
`;


const Container = styled.div`
  width: 75%;
  margin: 2rem auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;    // Change this from center to flex-start
  flex-wrap: wrap;
  gap: 2rem;                  // Add gap for consistent spacing

  @media (max-width: 64em) {
    width: 80%;
  }

  @media (max-width: 48em) {
    width: 90%;
    justify-content: center;
  }
`;

const Position = styled.h2`
  font-size: ${(props) => props.theme.fontmd};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: ${(props) => `rgba(${props.theme.textRgba}, 0.9)`};
  font-weight: 400;
`;

export default Team;
