import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Check } from 'lucide-react';
import "@fontsource/comic-neue/700.css"; // Bold weight

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const bounce = keyframes`
    0%, 100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(10px);
    }
`;

const ListContainer = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
`;

const ListItem = styled.li`
    display: flex;
    align-items: center;
    opacity: 0;
    margin: 0.1rem 0;
    animation: ${fadeIn} 0.6s ease forwards;
    animation-delay: ${props => props.index * 0.2}s;

    @media (max-width: 48em) {
        margin-bottom: 1rem;
    }
`;

const IconWrapper = styled.div`
    animation: ${bounce} 2s ease-in-out infinite;
    color: #00FF00; /* Dark green */
`;

const TextContent = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  font-style: bold;
    font-family: 'Comic Neue';
    font-weight: 600;
  color: ${(props) => props.theme.text};
  align-self: flex-start;
  margin: 0.7rem auto;
  width: 80%;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
  }
`;

const AnimatedList = ({ items }) => {
  return (
    <ListContainer>
      {items.map((item, index) => (
        <ListItem key={index} index={index}>
          <IconWrapper>
            <Check size={36} strokeWidth={6} />
          </IconWrapper>
          <TextContent>{item}</TextContent>
        </ListItem>
      ))}
    </ListContainer>
  );
};

export default AnimatedList;