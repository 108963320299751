import React from "react";
import styled from "styled-components";
import Typewriter from "typewriter-effect";
import Button from "./Button";
import "@fontsource/comic-neue";
import "@fontsource/comic-neue/700.css";
import Telegram from "../Icons/Telegram";
import X from "../Icons/X";
import LinkTree from "../Icons/LinkTree";
import Discord from "../Icons/Discord";

const TypeWriterText = () => {
  return (
    <>
      <Title>
        <p>Discover a new era of AI meme coins</p>
        <Typewriter
          options={{
            autoStart: true,
            loop: true,
          }}
          onInit={(typewriter) => {
            typewriter
              .typeString(`<span class="text-1">$JYAI</span>`)
              .pauseFor(2000)
              .deleteAll()
              .typeString(`<span class="text-2">JERRY The Turtle</span>`)
              .pauseFor(2000)
              .deleteAll()
              .typeString(`<span class="text-3">JYAI</span>`)
              .pauseFor(2000)
              .deleteAll()
              .typeString(`<span class="text-4">JERRY The Turtle</span>`)
              .pauseFor(2000)
              .deleteAll()
              .start();
          }}
        />
      </Title>
      <ButtonContainer>
        <IconList>
          <a href="https://linktr.ee/Jerrytheturtle" target="_blank" rel="noopener noreferrer">
            <LinkTree />
          </a>
          <a href="https://t.me/jyai_jerry" target="_blank" rel="noopener noreferrer">
            <Telegram />
          </a>
          <a href="https://x.com/JYAI_JERRY" target="_blank" rel="noopener noreferrer">
            <X />
          </a>
          <a href="https://discord.gg/jjNzYWVb" target="_blank" rel="noopener noreferrer">
            <Discord />
          </a>
        </IconList>
        <ContractText>
          Contract Address:<br/> 0x4E9623B7e5b6438542458f5EE828d65c24d3AF8c
        </ContractText>
      </ButtonContainer>
    </>
  );
};

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  font-weight: 500;
  width: 80%;
  color: ${(props) => props.theme.text};
  align-self: flex-start;
    margin-bottom: 2rem;

  span {
    text-transform: uppercase;
    font-family: 'Comic Neue';
      font-weight: 700;
  }

  .text {
    &-1 {
      color: lightseagreen;
    }
    &-2 {
      color: goldenrod;
    }
    &-3 {
      color: orangered;
    }
      &-4 {
      color: blueviolet;
    }
  }

  @media (max-width: 70em) {
    font-size: ${(props) => props.theme.fontxl};
  }

  @media (max-width: 48em) {
    align-self: center;
    text-align: center;
  }

  @media (max-width: 40em) {
    width: 90%;
  }
`;

const Subtitle = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  text-transform: capitalize;
    color: ${(props) => props.theme.text};
  margin-bottom: 1rem;
  font-weight: 500;
  width: 80%;
  align-self: flex-start;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 48em) {
    align-self: center;
    text-align: center;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
    align-self: center;
    text-align: center;
`;

const IconList = styled.div`
    width: 100%;
    margin: 1rem auto;
    display: flex;
    justify-content: center;
    gap: 1rem;

    a {
        display: inline-flex;
    }
`;

const ContractText = styled.p`
  color: ${(props) => props.theme.text};
  font-size: ${(props) => props.theme.fontlg};
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 1px;
  text-align: center;
  z-index: 10;
  
  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontmd};
  }
  
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontsm};
    padding: 0 1rem;
  }
`;


export default TypeWriterText;
