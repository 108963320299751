import React, { useRef } from "react";
import styled from "styled-components";
import { keyframes } from "styled-components";

import baby from "../../assets/Nfts/baby.svg";
import young from "../../assets/Nfts/young.svg";
import teen from "../../assets/Nfts/teen.svg";
import mature from "../../assets/Nfts/mature.svg";
import aging from "../../assets/Nfts/aging.svg";
import aged from "../../assets/Nfts/aged.svg";

import char1 from "../../assets/Nfts/char1.jpeg"
import char2 from "../../assets/Nfts/char2.jpeg"
import char3 from "../../assets/Nfts/char3.jpeg"
import char4 from "../../assets/Nfts/char4.jpeg"
import char5 from "../../assets/Nfts/char5.jpeg"
import char6 from "../../assets/Nfts/char6.jpeg"

const Showcase = () => {
  const Row1Ref = useRef(null);
  const Row2Ref = useRef(null);

  return (
    <Section id="showcase">
      <Row direction="none" ref={Row1Ref}>
      <Title>JERRY over the years</Title>
        <NftItem img={baby} number={852} price={1} passRef={Row1Ref} />
        <NftItem img={teen} number={564} price={1.5} passRef={Row1Ref} />
        <NftItem img={young} number={223} price={1.2} passRef={Row1Ref} />
        <NftItem img={mature} number={102} price={3.5} passRef={Row1Ref} />
        <NftItem img={aging} number={234} price={2} passRef={Row1Ref} />
        <NftItem img={aged} number={234} price={2} passRef={Row1Ref} />
      </Row>

      <Row direction="reverse" ref={Row2Ref}>
      <Title>JERRY Cinematic Universe</Title>
        <NftItem img={char1} number={852} price={1} passRef={Row2Ref} />
        <NftItem img={char2} number={223} price={1.2} passRef={Row2Ref} />
        <NftItem img={char3} number={564} price={1.5} passRef={Row2Ref} />
        <NftItem img={char4} number={102} price={3.5} passRef={Row2Ref} />
        <NftItem img={char5} number={234} price={2} passRef={Row2Ref} />
        <NftItem img={char6} number={234} price={2} passRef={Row2Ref} />
      </Row>
    </Section>
  );
};

const NftItem = ({ img, number = 0, price = 0, passRef }) => {
  let play = (e) => {
    passRef.current.style.animationPlayState = "running";
  };

  let pause = (e) => {
    passRef.current.style.animationPlayState = "paused";
  };

  return (
    <ImgContainer onMouseOver={(e) => pause(e)} onMouseOut={(e) => play(e)}>
      <img src={img}/>
    </ImgContainer>
  );
};

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.text};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  & > *:first-child {
    animation-duration: 20s;

    @media (max-width: 30em) {
      animation-duration: 15s;
    }
  }

  & > *:last-child {
    animation-duration: 15s;

    @media (max-width: 30em) {
      animation-duration: 10s;
    }
  }
`;

const move = keyframes`
  0%{
    transform: translateX(100%);
  }
  100%{
    transform: translateX(-100%);
  }
`;

const Row = styled.div`
  white-space: nowrap;
  box-sizing: content-box;
  margin: 2rem 0;
  display: flex;
  animation: ${move} linear infinite ${(props) => props.direction};
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
    color: ${(props) => props.theme.body};  
    display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
    padding: 3rem;

    width: fit-content;
    font-style: italic;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const ImgContainer = styled.div`
    width: 15rem;
    height: 15rem; // Added fixed height to maintain aspect ratio
    margin: 0 1rem;
    background-color: ${(props) => props.theme.body};
    border-radius: 20px;
    cursor: pointer;

    @media (max-width: 48em) {
        width: 12rem;
        height: 12rem; // Updated height for consistency
    }

    @media (max-width: 30em) {
        width: 10rem;
        height: 10rem; // Updated height for consistency
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover; // This ensures the image covers the container
        border-radius: 20px; // Added to maintain rounded corners
    }
`;

const Details = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 1rem;
  background-color: ${(props) => props.theme.text};
  border: 2px solid ${(props) => `rgba(${props.theme.bodyRgba}, 0.5)`};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  span {
    font-size: ${(props) => props.theme.fontsm};
    color: ${(props) => `rgba(${props.theme.bodyRgba}, 0.5)`};
    font-weight: 600;
    line-height: 1.5rem;
  }

  h1 {
    font-size: ${(props) => props.theme.fontmd};
    color: ${(props) => props.theme.body};
    font-weight: 600;

    @media (max-width: 30em) {
      font-size: ${(props) => props.theme.fontsm};
    }
  }
`;

const Price = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 1rem;
    height: auto;
  }
`;

export default Showcase;
