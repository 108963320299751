import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import DrawSvg from "../DrawSvg";

const Roadmap = () => {
  const revealRefs = useRef([]);
  revealRefs.current = [];

  gsap.registerPlugin(ScrollTrigger);

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };

  useLayoutEffect(() => {
    let t1 = gsap.timeline();
    revealRefs.current.forEach((el, index) => {
      t1.fromTo(
        el.childNodes[0],
        {
          y: "0",
        },
        {
          y: "-30%",
          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: el,
            start: "top center+=200px",
            end: "bottom center",
            scrub: true,
          },
        }
      );
    });

    return () => {};
  }, []);

  return (
    <Section id="roadmap">
      <Title>Exchange Listing Roadmap</Title>
      <Container>
        <SvgContainer>
          <DrawSvg />
        </SvgContainer>
        <Items>
          <Item>&nbsp;</Item>
          <RoadmapItem
            addToRefs={addToRefs}
            title="Phase 1: Foundation & Initial DEX Launch"
            subtext="Launch on Uniswap v2 (Ethereum)"
            marketCap={"Target market cap: $5K - $500K"}
            timeline="November-December 2024"
            strikeThrough={true}
            backgroundColor="green"
          />
          <RoadmapItem
            addToRefs={addToRefs}
            title="Phase 2: Market Expansion"
            subtext="Integration with Biconomy, AscendEX, AZBit, Dex-Trade, UZX, followed by TooBit, Weex, and Probit"
            marketCap={"Target market cap: $2.5M - $5M"}
            timeline="January-February 2025"
            backgroundColor="green"
          />
          <RoadmapItem
            addToRefs={addToRefs}
            title="Phase 3: Major Exchange Integration"
            subtext="Launching on FameeEX, P2B, Bigone, HotCoin, OrangeX, Coinstore, Crypto.com, Bybit, OKX, Bithumb, BitMart, followed by WhiteBIT, XT.com, DigiFinex, Coinone, and CoinDCX"
            marketCap={"Target market cap: $25M - $50M"}
            timeline="March-April 2025"
          />
          <RoadmapItem
            addToRefs={addToRefs}
            title="Phase 4: Top-Tier Exchange Integration"
            subtext="Final expansion to KuCoin, MexC Global, BingX, CoinW, Binance and Coinbase (pending eligibility)"
            marketCap={"Target market cap: $150M - $275M"}
            timeline="May-June 2025"
          />
          <RoadmapItem
            addToRefs={addToRefs}
            title="Phase 5: Coinbase listing and First Studio Release"
            subtext=""
            marketCap={"Target market cap: $275M+"}
            timeline="2025"
          />
        </Items>
      </Container>
    </Section>
  );
};

const RoadmapItem = ({ title, subtext, marketCap, timeline, addToRefs, strikeThrough=false, backgroundColor="transparent" }) => {
  return (
    <Item ref={addToRefs}>
      <ItemContainer style={{
        textDecoration: strikeThrough ? 'line-through' : 'none',
        backgroundColor: backgroundColor === "green" ? '#e6ffe6' : 'transparent'
      }}>
        <Box style={{
          backgroundColor: backgroundColor === "green" ? '#e6ffe6' : 'transparent'
        }}>
          <SubTitle>{title}</SubTitle>
          <Text>{subtext}</Text>
          <MarketCap>{marketCap}</MarketCap>
        </Box>
      </ItemContainer>
    </Item>
  );
};

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.body};
  position: relative;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
    font-style: italic;

    color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
    padding: 3rem;
  width: fit-content;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Container = styled.div`
  width: 70%;
  height: 200vh;
  background-color: ${(props) => props.theme.body};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 64em) {
    width: 80%;
  }

  @media (max-width: 48em) {
    width: 90%;
  }
`;

const SvgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Items = styled.ul`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 48em) {
    width: 90%;
  }

  & > *:nth-of-type(2n + 1) {
    justify-content: start;

    @media (max-width: 48em) {
      justify-content: center;
    }

    div {
      border-radius: 50px 0 50px 0;
      text-align: right;

      @media (max-width: 48em) {
        border-radius: 0 50px 0 50px;
        text-align: left;

        p {
          border-radius: 0 40px 0 40px;
        }
      }
    }
    p {
      border-radius: 40px 0 40px 0;
    }
  }

  & > *:nth-of-type(2n) {
    justify-content: end;

    @media (max-width: 48em) {
      justify-content: center;
    }

    div {
      border-radius: 0 50px 0 50px;
      text-align: left;
    }

    p {
      border-radius: 0 40px 0 40px;
    }
  }
`;

const Item = styled.li`
  width: 100%;
  height: 100%;
  display: flex;

  @media (max-width: 48em) {
    justify-content: flex-end !important;
  }
`;

const ItemContainer = styled.div`
  width: 40%;
  height: fit-content;
  padding: 1rem;
  border: 3px solid ${(props) => props.theme.text};

  @media (max-width: 48em) {
    width: 70%;
  }
`;

const Box = styled.p`
  height: fit-content;
  background-color: ${(props) => props.theme.carouselColor};
  color: ${(props) => props.theme.text};
  padding: 1rem;
  position: relative;
  border: 1px solid ${(props) => props.theme.text};
`;

const SubTitle = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  margin-bottom: 0.5rem;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontlg};
  }
`;

const Timeline = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => props.theme.text};
  font-weight: 400;
  margin-bottom: 0.5rem;
  font-style: italic;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontsm};
  }
`;

const Text = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontsm};
  font-weight: 400;
  margin: 0.5rem 0rem;
  color: ${(props) => props.theme.text};

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxs};
  }
`;

const MarketCap = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontmd};
  font-weight: 800;
    font-style: bold;
  margin: 0.5rem 0rem;
  color: ${(props) => props.theme.text};

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxs};
  }
`;

export default Roadmap;