import React from "react";
import styled from "styled-components";
import CoverVideo from "../CoverVideo";
import TypeWriterText from "../TypeWriterText";
import AnimatedList from "../AnimatedList";
import RoundTextBlack from "../../assets/Rounded-Text-Black.png";
import RoundTextWhite from "../../assets/Rounded-Text-White.png";
import { keyframes } from "styled-components";
import { useTheme } from 'styled-components';
import jerryHead from "../../assets/Nfts/jerryHead.jpeg";

const Home = (props) => {
  const theme = useTheme();

  const listItems = [
    "Audited By SolidProof",
    "Contract Renounced",
    "Liquidity Locked Forever",
    "Community Feedback Implemented Frequently"
  ];

  return (
    <Section id="home">
      <GridContainer>
        <LeftGrid>
          <TopHalf>
            <TypeWriterText/>
          </TopHalf>
          <BottomHalf>
            <AnimatedList items={listItems} />
          </BottomHalf>
        </LeftGrid>
        <RightGrid>
          <StyledImage src={jerryHead} alt="Jerry Head" />
        </RightGrid>
        <RoundText>
          <Circle>&#x2193;</Circle>
        </RoundText>
      </GridContainer>
    </Section>
  );
};

const Section = styled.section`
    min-height: ${(props) => `calc(100vh - ${props.theme.navHeight})`};
    width: 100vw;
    position: relative;
    background-color: ${(props) => props.theme.body};
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 100vh;
    width: 100%;
    position: relative;
    padding: 3rem 5rem;  // Add padding here (top/bottom: 3rem, left/right: 5rem)
    box-sizing: border-box;  // Ensures padding is included in width calculation

    @media (max-width: 64em) {
        padding: 2rem 3rem;  // Slightly less padding on medium screens
    }

    @media (max-width: 48em) {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        padding: 1.5rem 2rem;  // Even less padding on mobile
    }
`;

const LeftGrid = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  padding: 2rem;

  @media (max-width: 48em) {
    order: 2;
  }
`;

const TopHalf = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BottomHalf = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const RightGrid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (max-width: 48em) {
    order: 1;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 100vh;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: scale(1.05);
  }
`;

const RoundText = styled.div`
  position: absolute;
  left: 5%;
  bottom: 2rem;
  width: 6rem;
  height: 6rem;
  border: 1px solid ${(props) => props.theme.text};
  border-radius: 50%;

  @media (max-width: 64em) {
    width: 4rem;
    height: 4rem;
    left: auto;
    right: 2rem;
    bottom: 100%;
  }

  @media (max-width: 48em) {
    right: 1rem;
  }
`;

const Circle = styled.span`
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${(props) => props.theme.text};
  color: ${(props) => props.theme.body};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${(props) => props.theme.fontxl};

  @media (max-width: 64em) {
    width: 2rem;
    height: 2rem;
    font-size: ${(props) => props.theme.fontlg};
  }
`;

export default Home;