export const Light = {
  name: "light",
  body: "#FAFAFA",        // Soft white background
  text: "#1A1A1A",        // Soft black for better readability
  bodyRgba: "250, 250, 250",
  textRgba: "26, 26, 26",

  carouselColor: "#F0F0F0", // Subtle grey for carousel

  fontxs: "0.75rem",
  fontsm: "0.875rem",
  fontmd: "1rem", // 1rem = 16px
  fontlg: "1.25rem",
  fontxl: "2rem",
  fontxxl: "3rem",
  fontxxxl: "4rem",

  fontButton: "0.875rem",

  navHeight: "5rem",

  // Added properties for ThemeToggle
  bg: "#FAFAFA",       // Background color for toggle (same as body)
  sun: "#FFA500",      // Orange color for sun icon
  moon: "#D6C2B5",     // Light color for moon icon
  shadow: "#BEBEBE",   // Light grey for shadow effects
  light: "#FFFFFF",    // Pure white for highlights
};

export const Dark = {
  name: "dark",
  body: "#121212",        // Material dark theme background
  text: "#F5F5F5",        // Off-white for better eye comfort
  bodyRgba: "18, 18, 18",
  textRgba: "245, 245, 245",

  carouselColor: "#2A2A2A", // Darker grey for carousel in dark mode

  fontxs: "0.75rem",
  fontsm: "0.875rem",
  fontmd: "1rem", // 1rem = 16px
  fontlg: "1.25rem",
  fontxl: "2rem",
  fontxxl: "3rem",
  fontxxxl: "4rem",

  fontButton: "0.875rem",

  navHeight: "5rem",

  // Added properties for ThemeToggle
  bg: "#121212",      // Background color for toggle (same as body)
  sun: "#8DC4D1",     // Blue color for sun icon
  moon: "#FFFFFF",    // White for moon icon
  shadow: "#2E2E2E",  // Dark grey for shadow effects
  light: "#4D4D4D",   // Darker grey for highlights
};
