import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import Accordion from "../Accordion";

const Faqs = () => {
  const ref = useRef(null);

  gsap.registerPlugin(ScrollTrigger);

  useLayoutEffect(() => {
    let element = ref.current;

    ScrollTrigger.create({
      trigger: element,
      start: "top top",
      bottom: "bottom top",
      pin: true,
      pinSpacing: false,
      scrub: true,
    });
    return () => {
      ScrollTrigger.kill();
    };
  }, []);

  return (
    <Section ref={ref} id="faqs">
      <Title>FAQs</Title>
      <Container>
        <Box>
          <Accordion title="WHAT IS JERRY TOKEN?">
            JERRY (JYAI) is an ERC-20 token on the Ethereum blockchain with a total supply of 69
            Billion tokens. It features zero tax and a deflationary mechanism, making it ideal for
            long-term holders. The token combines meme culture with AI-powered content creation
            and maintains a strong community-first approach.
          </Accordion>
          <Accordion title="HOW IS THE TOKEN SUPPLY DISTRIBUTED?">
            85% (58.65B JERRY) is allocated for public trading and community, 2.41% (1.6629B JERRY)
            for the Production House Fund, 6.9% (4.761B JERRY) for exchange listing reserves,
            0.69% (0.476B JERRY) for the Matt Furie Appreciation Fund, and 5% (3.45B JERRY) for
            founder allocation. This distribution ensures fair market participation and project sustainability.
          </Accordion>
          <Accordion title="WHAT SECURITY MEASURES ARE IN PLACE?">
            JERRY implements multiple security measures including multi-signature wallets for exchange
            listing reserves, completed smart contract audits, liquidity lock mechanisms, and a
            transparent burning process. Additionally, we have transaction monitoring systems and
            a community alert system in place.
          </Accordion>
        </Box>
        <Box>
          <Accordion title="HOW DOES THE COMMUNITY PARTICIPATE IN GOVERNANCE?">
            JERRY is fundamentally community-driven. All major decisions affecting the token's future
            are made with direct community input through voting and participation via our social channels. We maintain full
            transparency in operations and believe in the collective wisdom of our holders.
          </Accordion>
          <Accordion title="WHAT IS THE EXCHANGE LISTING ROADMAP?">
            Our growth strategy follows a four-phase approach: Launch Phase (Target MC: $5K - $500K),
            Growth Phase (Target MC: $2.5M - $5M), Expansion Phase (Target MC: $25M - $50M), and
            Major CEX Phase (Target MC: $150M - $275M). Each phase involves strategic exchange
            listings to support sustainable growth. We are already listed at Biconomy, AscendX, Dextrade, UZX and Azbit with more to come!!
          </Accordion>
          <Accordion title="HOW DOES JERRY HANDLE INFLUENCER MARKETING?">
            While we welcome influencer promotion, we maintain strict guidelines prohibiting pump
            and dump schemes, price predictions, or financial advice. All promotions must focus on
            factual project information with clear disclosure of any compensation. Market manipulation
            is strictly prohibited.
          </Accordion>
        </Box>
      </Container>
    </Section>
  );
};

const Section = styled.section`
  min-height: 100vh;
  height: auto;
  width: 100vw;
  background-color: ${(props) => props.theme.text};
  color: ${(props) => props.theme.body};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.h1`
    font-size: ${(props) => props.theme.fontxxl};
    text-transform: uppercase;
    font-style: italic;
    color: ${(props) => props.theme.body};
    margin: 1rem auto;
    width: fit-content;
    @media (max-width: 48em) {
        font-size: ${(props) => props.theme.fontxl};
    }
`;

const Container = styled.div`
    width: 75%;
    margin: 2rem auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 64em) {
        width: 80%;
    }

    @media (max-width: 48em) {
        width: 90%;
        flex-direction: column;

        & > *::last-child {
            & > *::first-child {
                margin-top: 0;
            }
        }
    }
`;

const Box = styled.div`
  width: 45%;

  @media (max-width: 64em) {
    width: 90%;
    align-self: center;
  }
`;

export default Faqs;