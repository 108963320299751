import React from "react";
import styled from "styled-components";
import FooterBanner from "./FooterBanner";
import Logo from "./Logo";
import X from "../Icons/X";
import Telegram from "../Icons/Telegram";
import LinkTree from "../Icons/LinkTree";
import Discord from "../Icons/Discord";

const Footer = () => {
  const scrollTo = (id) => {
    let element = document.getElementById(id);

    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  return (
    <Section>
      <FooterBanner />
      <Container>
        <LeftContent>
          <Logo />
          <IconList>
            <a href="https://linktr.ee/Jerrytheturtle" target="_blank" rel="noopener noreferrer">
              <LinkTree/>
            </a>
            <a href="https://t.me/jyai_jerry" target="_blank" rel="noopener noreferrer">
              <Telegram/>
            </a>
            <a href="https://x.com/JYAI_JERRY" target="_blank" rel="noopener noreferrer">
              <X/>
            </a>
            <a href="https://discord.gg/jjNzYWVb" target="_blank" rel="noopener noreferrer">
              <Discord/>
            </a>
          </IconList>
        </LeftContent>

        <ListItems>
          <Item onClick={() => scrollTo("home")}>Home</Item>
          <Item onClick={() => scrollTo("about")}>About</Item>
          <Item onClick={() => scrollTo("roadmap")}>Roadmap</Item>
          <Item onClick={() => scrollTo("showcase")}>Showcase</Item>
          <Item onClick={() => scrollTo("team")}>Team</Item>
          <Item onClick={() => scrollTo("faqs")}>Faqs</Item>
        </ListItems>
      </Container>
      <DisclaimerContainer>
        <DisclaimerCard>
          <DisclaimerTitle>Disclaimer</DisclaimerTitle>
          <DisclaimerIntro>
            This website is an independent, community-driven platform and does NOT officially represent JYAI.
            The content provided is solely for entertainment and informational purposes.
          </DisclaimerIntro>

          <DisclaimerGrid>
            <DisclaimerSection>
              <SectionTitle>Not Financial Advice</SectionTitle>
              <SectionText>
                This website does not provide financial or investment advice. Always consult a professional
                before making any financial decisions involving JYAI or any other cryptocurrency.
              </SectionText>
            </DisclaimerSection>

            <DisclaimerSection>
              <SectionTitle>No Central Ownership or Authority</SectionTitle>
              <SectionText>
                JYAI has no central owner or authority. It is a fully decentralized token, meaning it can be
                used by anyone in any manner they choose, in accordance with applicable laws and regulations.
              </SectionText>
            </DisclaimerSection>

            <DisclaimerSection>
              <SectionTitle>No Profit Generation or Treasury</SectionTitle>
              <SectionText>
                JYAI does not generate profit, nor does it maintain a treasury. It is a community-driven
                project without any central financial authority.
              </SectionText>
            </DisclaimerSection>

            <DisclaimerSection>
              <SectionTitle>Collaborative Partnerships</SectionTitle>
              <SectionText>
                JYAI partnerships consist solely of collaborations and do not involve formal contracts or
                agreements. The JYAI project does not endorse or vet any third-party applications, exchanges,
                or platforms where JYAI may be traded or used.
              </SectionText>
            </DisclaimerSection>

            <DisclaimerSection>
              <SectionTitle>Market and Legal Risks</SectionTitle>
              <SectionText>
                Cryptocurrencies are highly volatile. The value of JYAI may fluctuate, and there are no
                guarantees of future performance. Participants are responsible for any losses incurred.
                Additionally, participants should be aware of ongoing regulatory developments that may impact
                the use or trading of decentralized tokens such as JYAI.
              </SectionText>
            </DisclaimerSection>

            <DisclaimerSection>
              <SectionTitle>Regulatory Compliance</SectionTitle>
              <SectionText>
                JYAI is a decentralized token and is not classified as a security. Users are responsible
                for complying with all applicable local KYC and AML laws and regulations when engaging with JYAI.
              </SectionText>
            </DisclaimerSection>

            <DisclaimerSection>
              <SectionTitle>No Guarantees of Profit or Future Value</SectionTitle>
              <SectionText>
                There are no guarantees of profit or future value. Participation in the JYAI ecosystem is
                at your own risk.
              </SectionText>
            </DisclaimerSection>

            <DisclaimerSection>
              <SectionTitle>Jurisdiction and User Responsibility</SectionTitle>
              <SectionText>
                Certain countries may restrict or prohibit cryptocurrency use. It is the user's responsibility
                to comply with all local regulations.
              </SectionText>
            </DisclaimerSection>
          </DisclaimerGrid>
        </DisclaimerCard>
      </DisclaimerContainer>

      <Credits>
        <span>
          &copy;{new Date().getFullYear()} JERRY Coin. All Rights Reserved.
        </span>
        <span>
          Made with 💖 by {""}
            JERRY Team
        </span>
      </Credits>
    </Section>
  );
};

const Credits = styled.div`
    width: 75%;
    margin: 2rem auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
        text-decoration: underline;
    }

    @media (max-width: 48em) {
        flex-direction: column;
        width: 100%;

        span {
            margin-bottom: 1rem;
        }
    }
`;

const Item = styled.li`
    width: fit-content;
    cursor: pointer;

    &::after {
        content: "";
        display: block;
        width: 0%;
        height: 2px;
        background: ${(props) => props.theme.text};
        transition: width 0.3s ease;
    }

    &:hover {
        &::after {
            width: 100%;
        }
    }
`;

const ListItems = styled.ul`
  width: 50%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 1rem;

  @media (max-width: 48em) {
    display: none;
  }
`;

const IconList = styled.div`
    display: flex;
    align-items: center;
    margin: 1rem auto;
    gap: 1rem;
`;

const SocialLink = styled.a`
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        transform: scale(1.2);
        opacity: 0.8;
    }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 48em) {
    width: 100%;
  }
`;

const Container = styled.div`
    width: 75%;
    margin: 2rem auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${(props) => props.theme.text};

    @media (max-width: 48em) {
        width: 90%;

        h1 {
            font-size: ${(props) => props.theme.fontxxxl};
        }
    }
`;

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};
  position: relative;
  display: flex;
  flex-direction: column;
`;

const DisclaimerIntro = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  color: ${props => props.theme.text};
  font-weight: 500;
  border-bottom: 1px solid ${props => props.theme.text}20;
  padding-bottom: 1.5rem;
`;

const DisclaimerSections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const DisclaimerTitle = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
    padding: 3rem;

    width: fit-content;
    font-style: italic;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;


const DisclaimerContainer = styled.div`
  width: 75%;
  margin: 2rem auto;
  
  @media (max-width: 48em) {
    width: 90%;
  }
`;

const DisclaimerCard = styled.div`
  background-color: ${props => props.theme.body};
  border: 1px solid ${props => props.theme.text}20;
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const DisclaimerText = styled.p`
  font-size: 0.9rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  color: ${props => props.theme.text}90;
`;

const DisclaimerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  
  @media (max-width: 48em) {
    grid-template-columns: 1fr;
  }
`;

const DisclaimerSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const SectionTitle = styled.h3`
  font-size: 1.1rem;
  font-weight: 600;
  color: ${props => props.theme.text};
`;

const SectionText = styled.p`
  font-size: 0.9rem;
  line-height: 1.6;
  color: ${props => props.theme.text}90;
`;

export default Footer;
